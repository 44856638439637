<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <b-overlay
              :show="showOverLay"
              rounded="sm"
              :opacity="0.5"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">

                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                  <!-- Header: Left Content -->
                  <div>
                    <div class="logo-wrapper">
                      <logo />
                      <h3 class="text-primary invoice-logo">
                        {{ $store.state.appConfig.layout.isRTL ? one_organization.name_ar : one_organization.name_en }}
                      </h3>
                    </div>
                    <b-card-text class="mb-25">
                      {{ one_organization.address }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ one_organization.email }}, {{ one_organization.website }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ one_organization.phone_one }}, {{ one_organization.phone_tow }}
                    </b-card-text>
                  </div>

                  <!-- Header: Right Content -->
                  <div class="invoice-number-date mt-md-0 mt-2">
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">
                        Invoice
                      </h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="invoice-data-id"
                          v-model="id"
                          disabled
                        />
                      </b-input-group>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                      <span class="title">
                        Date Issued:
                      </span>
                      <flat-pickr
                        v-model="invoiceData.date"
                        class="form-control invoice-edit-input"
                      />
                    </div>
                    <div class="d-flex align-items-center">
                      <span class="title">
                        Due Date:
                      </span>
                      <flat-pickr
                        v-model="invoiceData.dueDate"
                        class="form-control invoice-edit-input"
                      />
                    </div>
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              <b-card-body
                class="invoice-padding pt-0"
              >
                <b-row class="invoice-spacing">

                  <!-- Col: Invoice To -->
                  <b-col
                    cols="12"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      Invoice To:
                    </h6>

                    <!-- Select Client -->
                    <v-select
                      v-model="invoiceData.client"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      :options="marge_vendor_bank"
                      :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                      input-id="invoice-data-client"
                      :clearable="false"
                    >
                      <template #list-header>
                        <li
                          v-b-toggle.sidebar-invoice-add-new-customer
                          class="add-new-client-header d-flex align-items-center my-50"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            size="16"
                          />
                          <span class="align-middle ml-25">Add New Customer</span>
                        </li>
                      </template>
                    </v-select>

                    <!-- Selected Client -->
                    <div
                      v-if="invoiceData.client"
                      class="mt-3"
                    >
                      <h6 class="mb-25">
                        {{ $store.state.appConfig.layout.isRTL ? invoiceData.client.name_ar : invoiceData.client.name_en }}
                      </h6>
                      <b-card-text class="mb-25">
                        {{ $store.state.appConfig.layout.isRTL ? invoiceData.client.company.name_ar : invoiceData.client.company.name_en }}
                      </b-card-text>
                      <b-card-text class="mb-25">
                        {{ invoiceData.client.address }}, {{ invoiceData.client.area_id }}
                      </b-card-text>
                      <b-card-text class="mb-25">
                        {{ invoiceData.client.phone }}, {{ invoiceData.client.tel }}
                      </b-card-text>
                      <b-card-text class="mb-0">
                        {{ invoiceData.client.email }}
                      </b-card-text>
                    </div>
                  </b-col>
                  <!-- Col: Document -->
                  <b-col
                    cols="12"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      Document:
                    </h6>

                    <!-- Select Document -->
                    <v-select
                      v-model="document_id"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      :options="document_option"
                      :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                      input-id="invoice-data-client"
                      :clearable="false"
                      :change="getInvoicNo()"
                    />
                    <div v-if="invoiceData.client">
                      <h6 class="mt-3">
                        Payment Details:
                      </h6>
                      <table>
                        <tbody>
                          <tr>
                            <td class="pr-1">
                              Total Due:
                            </td>
                            <td><span class="font-weight-bold">${{ total_price }}</span></td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              Bank Name:
                            </td>
                            <td>{{ $store.state.appConfig.layout.isRTL ? invoiceData.client.bank_branch.bank.name_ar : invoiceData.client.bank_branch.bank.name_en }}</td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              IBAN:
                            </td>
                            <td>{{ invoiceData.client.IBAN_account_number }}</td>
                          </tr>
                          <tr>
                            <td class="pr-1">
                              Bank Card:
                            </td>
                            <td>{{ invoiceData.client.bank_card }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- Selected Doucment -->
                  </b-col>
                  <!-- Col: Payment Details -->
                </b-row>
              </b-card-body>

              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <b-row
                    v-for="(item, index) in invoiceData.items"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >

                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">

                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            Item
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            Price
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            Qty
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            Total
                          </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <label class="d-inline d-lg-none">Item</label>
                            <v-select
                              v-model="item.item"
                              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                              :options="item_option"
                              :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                              :clearable="false"
                              class="mb-2 item-selector-title"
                              placeholder="Select Item"
                              @input="val => updateItemForm(index, val)"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <label class="d-inline d-lg-none">Price</label>
                            <b-form-input
                              v-model="item.price"
                              type="number"
                              class="mb-2"
                              @keyup="sumForm()"
                            />
                            <b-form-input
                              v-model="item.item_id"
                              type="number"
                              class="mb-2 d-none"
                            />
                            <b-form-input
                              v-model="item.measurement_unit_id"
                              type="number"
                              class="mb-2 d-none"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none">Qty</label>
                            <b-form-input
                              v-model="item.quantity"
                              type="number"
                              class="mb-2"
                              @keyup="sumForm()"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none">Total</label>
                            <p class="mb-1">
                              ${{ item.price * item.quantity }}
                            </p>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <label class="d-inline d-lg-none">Measurement Unit</label>
                            <v-select
                              v-model="item.measurement"
                              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                              :options="measurement_unit_option"
                              :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                              :clearable="false"
                              class="mb-2 item-selector-title"
                              placeholder="Select Measurement Unit"
                              @input="val => updateMeasurementForm(index, val)"
                            />
                          </b-col>
                        </b-row>
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemForm"
                >
                  Add Item
                </b-button>
              </b-card-body>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>

                  <!-- Col: Sales Persion -->
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3 align-items-center"
                    order="2"
                    order-md="1"
                  >
                    <label
                      for="invoice-data-sales-person"
                      class="text-nowrap mr-50"
                    >Warehouse:</label>
                    <v-select
                      v-model="warehouse_id"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      :options="warehouse_option"
                      :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                      input-id="payment-method"
                      class="payment-selector"
                      :clearable="false"
                    />
                  </b-col>

                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Subtotal:
                        </p>
                        <p class="invoice-total-amount">
                          ${{ total_price }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Discount:
                        </p>
                        <p class="invoice-total-amount">
                          $0
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Tax:
                        </p>
                        <p class="invoice-total-amount">
                          0%
                        </p>
                      </div>
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Total:
                        </p>
                        <p class="invoice-total-amount">
                          ${{ total_price }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">Note: </span>
                <b-form-textarea v-model="invoiceData.note" />
              </b-card-body>
            </b-overlay>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >

        <!-- Action Buttons -->
        <b-card>

          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            disabled
          >
            Send Invoice
          </b-button>

          <!-- Button: DOwnload -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
          >
            Preview
          </b-button>
          <!-- Button: Reset -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            type="reset"
            variant="outline-primary"
            class="mb-75"
            block
            @click="clearForm"
          >
            Reset
          </b-button>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            @click="postApi"
          >
            Save
          </b-button>
        </b-card>

        <!-- Payment Method -->
        <div class="mt-2">
          <b-form-group
            label="Status"
            label-for="status"
          >
            <v-select
              v-model="status_id"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="status_option"
              input-id="payment-method"
              :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
              class="payment-selector"
              :clearable="false"
            />
          </b-form-group>

          <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->

          <!-- Payment Terms -->
          <div class="d-flex justify-content-between align-items-center">
            <label for="patymentTerms">Suspended</label>
            <b-form-checkbox
              id="patymentTerms"
              v-model="suspended"
              :checked="true"
              switch
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <invoice-sidebar-add-new-customer />
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, VBToggle, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import organization from '@/service/administration/organization'
import document from '@/service/administration/document'
import supplier from '@/service/crm/supplier'
import warehouse from '@/service/warehouse/warehouse'
import payment from '@/service/administration/payment'
import measurementUnit from '@/service/warehouse/measurement-unit'
import item from '@/service/warehouse/item'
import operation from '@/service/warehouse/operation'
import status from '@/service/administration/status'
import bankBranch from '@/service/administration/bank-branch'
import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'
import invoiceStoreModule from '../invoiceStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    flatPickr,
    vSelect,
    Logo,
    InvoiceSidebarAddNewCustomer,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  data() {
    return {
      id: 0,
      total_price: 0,
      suspended: false,
      organization_id: 1,
      one_organization: '10',
      document_id: '',
      document_option: [],
      vendor_set_id: '',
      vendor_set_option: [],
      warehouse_id: '',
      warehouse_option: [],
      measurement_unit_id: '',
      measurement_unit_option: [],
      payment_id: '',
      payment_option: [],
      item_id: '',
      item_option: [],
      bank_branch_option: [],
      marge_vendor_bank: [],
      status_id: '',
      status_option: [],
      showError: false,
      showOverLay: false,
      apiErrors: [],
      count: 0,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    this.showOverLay = true
    window.addEventListener('resize', this.initTrHeight)
    this.getApiSelect()
    setTimeout(() => { this.getInvoicNo() }, 500)
    // setTimeout(() => { this.showOverLay = false }, 1000)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
      this.count += 1
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    async postApi() {
      this.showOverLay = true
      const user = JSON.parse(localStorage.getItem('userData'))
      const resultData = await operation.postAxios({
        date: this.invoiceData.date,
        total_price: this.total_price,
        document_type_id: this.document_id.id,
        vendor_set_id: this.vendor_set_id.id,
        warehouse_id: this.warehouse_id.id,
        description: this.invoiceData.note,
        current_document_no: this.document_id == null ? '' : this.document_id.static_code,
        document_no: this.document_id.static_code,
        model_id: this.document_id.id,
        status_id: this.status_id.id,
        user_id: user.id,
        items: this.invoiceData.items,
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
        this.showOverLay = false
        return error
      })
      setTimeout(() => {
        if (resultData.data) {
          // this.$refs.formName.reset()
          this.showError = false
          this.showToast('Notification', 'CheckCircleIcon', 'Sales Order saved successfully.👍', 'success')
        } else {
          this.showToast('Error', 'XCircleIcon', 'Sales Order Not saved', 'danger')
        }
        this.showOverLay = false
      }, 1000)
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    async getApiSelect() {
      this.showOverLay = true
      const resultDocument = await document.getAxios().catch(error => {
        this.apiErrors = error
        this.showError = true
        return error
      })
      if (resultDocument.data) {
        this.document_option = resultDocument.data
        // eslint-disable-next-line prefer-destructuring
        this.document_id = this.document_option[0]
        this.showError = false
      }
      const resultVendorSet = await supplier.searchAxios(2).catch(error => {
        this.apiErrors = error
        this.showError = true
        return error
      })
      if (resultVendorSet.data) {
        this.vendor_set_option = resultVendorSet.data
        // eslint-disable-next-line prefer-destructuring
        this.vendor_set_id = this.vendor_set_option[0]
        this.showError = false
      }
      const resultWarehouse = await warehouse.getAxios().catch(error => {
        this.apiErrors = error
        this.showError = true
        return error
      })
      if (resultWarehouse.data) {
        this.warehouse_option = resultWarehouse.data
        // eslint-disable-next-line prefer-destructuring
        this.warehouse_id = this.warehouse_option[0]
        this.showError = false
      }
      const resultItem = await item.getAxios().catch(error => {
        this.apiErrors = error
        this.showError = true
        return error
      })
      if (resultItem.data) {
        this.item_option = resultItem.data
        // eslint-disable-next-line prefer-destructuring
        this.item_id = this.item_option[0]
        this.showError = false
      }
      const resultMeasurementUnit = await measurementUnit.getAxios().catch(error => {
        this.apiErrors = error
        this.showError = true
        return error
      })
      if (resultMeasurementUnit.data) {
        this.measurement_unit_option = resultMeasurementUnit.data
        // eslint-disable-next-line prefer-destructuring
        this.measurement_unit_id = this.measurement_unit_option[0]
        this.showError = false
      }
      const resultPayment = await payment.getAxios().catch(error => {
        this.apiErrors = error
        this.showError = true
        return error
      })
      if (resultPayment.data) {
        this.payment_option = resultPayment.data
        // eslint-disable-next-line prefer-destructuring
        this.payment_id = this.payment_option[0]
        this.showError = false
      }
      const resultOrganization = await organization.oneAxios(this.organization_id).catch(error => {
        this.apiErrors = error
        this.showError = true
        return error
      })
      if (resultOrganization.data) {
        this.one_organization = resultOrganization.data
        this.showError = false
      }
      const resultBankBranch = await bankBranch.getAxios().catch(error => {
        this.apiErrors = error
        this.showError = true
        return error
      })
      if (resultBankBranch.data) {
        this.bank_branch_option = resultBankBranch.data
        this.showError = false
      }
      const resultStatus = await status.getAxios().catch(error => {
        this.apiErrors = error
        this.showError = true
        return error
      })
      if (resultStatus.data) {
        this.status_option = resultStatus.data
        // eslint-disable-next-line prefer-destructuring
        this.status_id = this.status_option[0]
        this.showError = false
      }
      setTimeout(() => {
        this.marge_vendor_bank = this.vendor_set_option.map(vendor => {
          // eslint-disable-next-line camelcase
          const bank_branch = this.bank_branch_option.find(x => x.id === vendor.bank_branch_id)
          return { ...vendor, bank_branch }
        })
      }, 1000)
      this.showOverLay = false
    },
    async getInvoicNo() {
      const resultOperation = await operation.lastIdAxios(this.document_id.id).catch(error => {
        this.apiErrors = error
        this.showError = true
        return error
      })
      if (resultOperation.data || resultOperation.data === 0) {
        this.id = this.document_id.static_code + (resultOperation.data + 1)
        this.showError = false
      }
    },
    sumForm() {
      this.total_price = this.invoiceData.items.reduce((a, b) => +a + +b.quantity * b.price, 0)
    },
    clearForm() {
      this.id = 0
      this.total_price = 0
      this.suspended = false
      this.organization_id = 1
      // eslint-disable-next-line prefer-destructuring
      this.document_id = this.document_option[0]
      this.vendor_set_id = null
      // eslint-disable-next-line prefer-destructuring
      this.warehouse_id = this.warehouse_option[0]
      this.measurement_unit_id = null
      this.item_id = null
      // eslint-disable-next-line prefer-destructuring
      this.status_id = this.status_option[0]
      this.showError = false
      this.showOverLay = false
      this.apiErrors = []
      this.invoiceData.date = ''
      this.invoiceData.dueDate = ''
      this.invoiceData.items = [JSON.parse(JSON.stringify(this.itemFormBlankItem))]
      this.invoiceData.note = 'It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!'
      this.invoiceData.client = null
      this.$refs.form.style.overflow = 'hidden'
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.count; i++) {
        this.trTrimHeight(this.$refs.row[0].offsetHeight)
      }
      this.count = 0
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const clients = ref([])
    store.dispatch('app-invoice/fetchClients')
      .then(response => { clients.value = response.data })

    const itemFormBlankItem = {
      item: null,
      item_id: 0,
      price: 0,
      quantity: 0,
      measurement: null,
      measurement_unit_id: 0,
    }

    const invoiceData = ref({
      id: 5037,
      date: '',
      dueDate: '',
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      salesPerson: '',
      note: 'It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!',
      paymentMethod: null,
    })

    const itemsOptions = [
      {
        itemTitle: 'App Design',
        cost: 24,
        qty: 1,
        description: 'Designed UI kit & app pages.',
      },
      {
        itemTitle: 'App Customization',
        cost: 26,
        qty: 1,
        description: 'Customization & Bug Fixes.',
      },
      {
        itemTitle: 'ABC Template',
        cost: 28,
        qty: 1,
        description: 'Bootstrap 4 admin template.',
      },
      {
        itemTitle: 'App Development',
        cost: 32,
        qty: 1,
        description: 'Native App Development.',
      },
    ]

    const updateItemForm = (index, val) => {
      // const { cost, qty, description } = val
      invoiceData.value.items[index].item_id = val.id
      // invoiceData.value.items[index].qty = qty
      // invoiceData.value.items[index].description = description
    }
    const updateMeasurementForm = (index, val) => {
      invoiceData.value.items[index].measurement_unit_id = val.id
    }
    // const sumForm = (index, val) => {
    //   invoiceData.value.items[index].total = invoiceData.value.items.reduce((a, b) => +a + +b.quantity * b.price, 0)
    //   // invoiceData.value.items[index].measurement_unit_id = val.id
    // }

    const paymentMethods = [
      'Bank Account',
      'PayPal',
      'UPI Transfer',
    ]

    return {
      invoiceData,
      clients,
      itemsOptions,
      updateItemForm,
      itemFormBlankItem,
      paymentMethods,
      updateMeasurementForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
